import(/* webpackMode: "eager" */ "/vercel/path0/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/ui/CollapsibleNav.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/@sanity/next-loader/dist/client-components/live-stream.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/@sanity/next-loader/dist/client-components/live.js");
;
import(/* webpackMode: "eager", webpackExports: ["Analytics"] */ "/vercel/path0/node_modules/@vercel/analytics/dist/react/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["SpeedInsights"] */ "/vercel/path0/node_modules/@vercel/speed-insights/dist/next/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/next-sanity/dist/visual-editing/client-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/local/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":\"./fonts/GeistVF.woff\",\"variable\":\"--font-geist-sans\",\"weight\":\"100 900\"}],\"variableName\":\"geistSans\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/local/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":\"./fonts/GeistMonoVF.woff\",\"variable\":\"--font-geist-mono\",\"weight\":\"100 900\"}],\"variableName\":\"geistMono\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/local/target.css?{\"path\":\"app/fonts/redaction.tsx\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"./redaction/Redaction-Regular.woff2\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"./redaction/Redaction-Italic.woff2\",\"weight\":\"400\",\"style\":\"italic\"},{\"path\":\"./redaction/Redaction-Bold.woff2\",\"weight\":\"700\",\"style\":\"bold\"}],\"variable\":\"--font-redaction\"}],\"variableName\":\"redaction\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/local/target.css?{\"path\":\"app/fonts/redaction.tsx\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"./redaction/Redaction_10-Regular.woff2\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"./redaction/Redaction_10-Italic.woff2\",\"weight\":\"400\",\"style\":\"italic\"},{\"path\":\"./redaction/Redaction_10-Bold.woff2\",\"weight\":\"700\",\"style\":\"bold\"}],\"variable\":\"--font-redaction-10\"}],\"variableName\":\"redaction10\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/local/target.css?{\"path\":\"app/fonts/redaction.tsx\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"./redaction/Redaction_20-Regular.woff2\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"./redaction/Redaction_20-Italic.woff2\",\"weight\":\"400\",\"style\":\"italic\"},{\"path\":\"./redaction/Redaction_20-Bold.woff2\",\"weight\":\"700\",\"style\":\"bold\"}],\"variable\":\"--font-redaction-20\"}],\"variableName\":\"redaction20\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/local/target.css?{\"path\":\"app/fonts/redaction.tsx\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"./redaction/Redaction_35-Regular.woff2\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"./redaction/Redaction_35-Italic.woff2\",\"weight\":\"400\",\"style\":\"italic\"},{\"path\":\"./redaction/Redaction_35-Bold.woff2\",\"weight\":\"700\",\"style\":\"bold\"}],\"variable\":\"--font-redaction-35\"}],\"variableName\":\"redaction35\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/local/target.css?{\"path\":\"app/fonts/redaction.tsx\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"./redaction/Redaction_50-Regular.woff2\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"./redaction/Redaction_50-Italic.woff2\",\"weight\":\"400\",\"style\":\"italic\"},{\"path\":\"./redaction/Redaction_50-Bold.woff2\",\"weight\":\"700\",\"style\":\"bold\"}],\"variable\":\"--font-redaction-50\"}],\"variableName\":\"redaction50\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/local/target.css?{\"path\":\"app/fonts/redaction.tsx\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"./redaction/Redaction_70-Regular.woff2\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"./redaction/Redaction_70-Italic.woff2\",\"weight\":\"400\",\"style\":\"italic\"},{\"path\":\"./redaction/Redaction_70-Bold.woff2\",\"weight\":\"700\",\"style\":\"bold\"}],\"variable\":\"--font-redaction-70\"}],\"variableName\":\"redaction70\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/local/target.css?{\"path\":\"app/fonts/redaction.tsx\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"./redaction/Redaction_100-Regular.woff2\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"./redaction/Redaction_100-Italic.woff2\",\"weight\":\"400\",\"style\":\"italic\"},{\"path\":\"./redaction/Redaction_100-Bold.woff2\",\"weight\":\"700\",\"style\":\"bold\"}],\"variable\":\"--font-redaction-100\"}],\"variableName\":\"redaction100\"}");
