"use client";

import { useState } from "react";

import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "@/components/ui/collapsible";
import { Project } from "@/lib/interface";
import { usePathname, useRouter } from "next/navigation";

interface CollapsibleNavProps {
  data: Project[];
}

export default function CollapsibleNav({ data }: CollapsibleNavProps) {
  const [isOpen, setIsOpen] = useState(false);
  const router = useRouter();
  const pathname = usePathname();

  // Toggle the collapsible menu
  const toggleCollapsible = () => setIsOpen((prev) => !prev);

  return (
    <Collapsible
      open={isOpen}
      onOpenChange={setIsOpen}
      className="transition-all duration-500"
    >
      <CollapsibleTrigger onClick={toggleCollapsible}>
        Projects
      </CollapsibleTrigger>
      <CollapsibleContent>
        {data.map((item) => (
          <button
            type="button"
            key={item._id}
            onClick={() => router.push(`/projects/${item.slug.current}`)}
            className={`group block w-full text-left ${
              pathname === `/projects/${item.slug.current}`
                ? "text-black"
                : "text-gray-400 hover:text-black"
            }`}
            rel="noopener noreferrer"
          >
            <div className="flex flex-row gap-1 items-center pl-4 font-normal justify-between transition-all duration-300">
              {item.title}
              {pathname === `/projects/${item.slug.current}` && <h1>✧</h1>}
            </div>
          </button>
        ))}
      </CollapsibleContent>
    </Collapsible>
  );
}
